var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[_vm._m(0),_c('div',{staticClass:"content"},[_c('div',[_c('p',{staticClass:"title"},[_vm._v("Source")]),_c('a-divider',{staticStyle:{"margin":"10px 0","background-image":"linear-gradient(to right,  rgb(103, 179, 241),  rgb(103, 179, 241), #f6f6f6, #f6f6f6)"}}),_c('p',[_vm._v("LibCity can only be installed from source code.")]),_c('p',[_vm._v("Please execute the following command to get the source code.")]),_vm._m(1),_c('p',[_vm._v("After obtaining the source code, you can configure the environment.")]),_c('br'),_c('p',{staticClass:"title"},[_vm._v("Requirements")]),_c('a-divider',{staticStyle:{"margin":"10px 0","background-image":"linear-gradient(to right,  rgb(103, 179, 241),  rgb(103, 179, 241), #f6f6f6, #f6f6f6)"}}),_vm._m(2),_c('p',[_vm._v("Pip:")]),_vm._m(3),_c('p',[_vm._v("Conda:")]),_vm._m(4),_c('p',[_vm._v("After installing Pytorch, you can install all the dependencies of LibCity with the following command by pip.")]),_vm._m(5),_vm._m(6),_c('br')],1)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"header"},[_c('div',{staticStyle:{"padding-top":"20px","color":"white"}},[_c('p',{staticStyle:{"margin":"20px 0 30px 130px","font-size":"60px"}},[_vm._v("Install LibCity")]),_c('p',{staticStyle:{"margin":"0px 0 20px 130px","font-size":"30px"}},[_vm._v(" LibCity can be installed from source. ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"code"},[_c('code',{attrs:{"data-lang":"bash"}},[_vm._v(" git clone https://github.com/LibCity/Bigscity-LibCity.git"),_c('br'),_vm._v(" cd Bigscity-LibCity ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v("Our code is based on Python version 3.7 and Pytorch version 1.7.1. You can click "),_c('a',{attrs:{"href":"https://pytorch.org/get-started/previous-versions/#v171","target":"_blank"}},[_vm._v("here")]),_vm._v(" to see how to install Pytorch. For example, if your cuda vision is 10.2, you can install Pytorch with the following command.")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"code"},[_c('code',{attrs:{"data-lang":"bash"}},[_vm._v(" pip install torch==1.7.1 torchvision==0.8.2 torchaudio==0.7.2 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"code"},[_c('code',{attrs:{"data-lang":"bash"}},[_vm._v(" conda install pytorch==1.7.1 torchvision==0.8.2 torchaudio==0.7.2 cudatoolkit=10.2 -c pytorch ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"code"},[_c('code',{attrs:{"data-lang":"bash"}},[_vm._v(" pip install -r requirements.txt ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v("Now, you can use LibCity, more details please refer to the section "),_c('a',{attrs:{"href":"https://libcity.ai/#/quick_start"}},[_vm._v("Quick Start")]),_vm._v(".")])
}]

export { render, staticRenderFns }